import {
  Box,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { createAdminApi, updateRoleAdminApi, updatePassword } from "api/admin.api";
import { getRoles } from "api/role.api";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import FormRadio from "components/form/FormRadio";
import { ROLE_OPTIONS } from "constants/constants";
import useWithToast from "hooks/useWithToast";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { schemaAdminManagement, schemaAdminManagementEdit } from "./schema";
import { useTranslation } from "react-i18next";

export default function AdminManagementCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const { t } = useTranslation("error");
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess, showToastFail } = useWithToast();

  const [roles, setRoles] = useState([]);
  const [roleOptions, setRoleOptions] = useState([]);
  const [typeEdit, setTypeEdit] = useState("role");

  const filterRoles = ["SUPPER_ADMIN", "USER"];

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  const form = useForm({
    resolver: yupResolver(
      idEdit ? schemaAdminManagementEdit(typeEdit) : schemaAdminManagement()
    ),
    defaultValues: {
      email: dataDetail ? dataDetail?.email : "",
      username: dataDetail ? dataDetail?.name : "",
      role: dataDetail ? dataDetail?.role_id : "",
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = form;

  const getRoleData = async () => {
    try {
      const { data } = await getRoles();
      const list = data?.data?.records;
      if (list?.length > 0) {
        setRoles(list);
        setRoleOptions(
          list
            ?.filter((item) => !filterRoles.includes(item?.name))
            ?.map((item) => ({
              label: ROLE_OPTIONS[item?.name],
              value: item?.id,
            }))
        );
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getRoleData();
  }, []);

  const onSubmit = async (data) => {
    const role = roles?.find((item) => item?.id === data?.role);
    const dataSubmit = {
      name: data?.username,
      email: data?.email,
      role_id: role?.id,
      status: "ACTIVE"
    };
    try {
      if (idEdit) {
        if (typeEdit === "role") {
          const res = await updateRoleAdminApi(idEdit, {
            role_id: role?.id,
          });
          if (res?.data?.success) {
            showToastSuccess({
              title: "Successfully edited",
            });
            handleCreateOrUpdateSuccess();
          }
        } else {
          const res = await updatePassword({
            new_password: data?.password,
            confirm_new_password: data?.cpassword,
            admin_id: idEdit
          });
          if (res?.data?.success) {
            showToastSuccess({
              title: "Successfully edited",
            });
            handleCreateOrUpdateSuccess();
          }
        }
      } else {
        const res = await createAdminApi({
          password: data?.password,
          ...dataSubmit,
        });
        if (res?.data?.success) {
          showToastSuccess({
            title: "Successfully created admin",
          });
          handleCreateOrUpdateSuccess();
        } else {
          showToastFail({
            title: t(`${res?.data?.messages[0]}`) || "Create fail!",
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Flex direction="column" gap={4}>
            <FormInput
              label="Email"
              name="email"
              placeholder="Enter username"
            />
            <FormInput
              label="Username"
              name="username"
              placeholder="Enter username"
            />
            <FormInput
              label="Password"
              name="password"
              placeholder="Enter password"
              autocomplete="new-password"
              type="password"
              renderRight
            />
            <Flex direction="column" alignItems="flex-start" gap="8px">
              <Text fontSize="16px" fontWeight="500">
                Role
              </Text>
              <Flex alignItems="center" gap="70px">
                <FormRadio name="role" options={roleOptions} />
              </Flex>
            </Flex>
          </Flex>
        </form>
      </FormProvider>
    );
  };

  const renderFormEdit = () => {
    return (
      <FormProvider {...form}>
        <Tabs 
          onChange={(index) => {
            if (index === 0) {
              setTypeEdit("role")
            } else {
              setTypeEdit("pass")
            }
          }}
        >
          <TabList mb='1em'>
            <Tab>Account Information</Tab>
            <Tab>Reset password</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
                <Flex direction="column" gap={4}>
                  <Box>
                    <Text fontSize="16px" fontWeight="500">Email</Text>
                    <Text fontSize='18px'>{watch("email")}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="16px" fontWeight="500">User Name</Text>
                    <Text fontSize='18px'>{watch("username")}</Text>
                  </Box>

                  <Flex direction="column" alignItems="flex-start" gap="8px">
                    <Text fontSize="16px" fontWeight="500">
                      Role
                    </Text>
                    <Flex alignItems="center" gap="70px">
                      <FormRadio name="role" options={roleOptions} />
                    </Flex>
                  </Flex>
                </Flex>
              </form>
            </TabPanel>
            <TabPanel>
              <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
                <Flex direction="column" gap={4}>
                  <FormInput
                    label="Password"
                    name="password"
                    placeholder="Enter password"
                    autocomplete="new-password"
                    type="password"
                    renderRight
                  />
                  <FormInput
                    label="Confirm Password"
                    name="cpassword"
                    placeholder="Enter your confirm password"
                    autocomplete="new-password"
                    type="password"
                    renderRight
                  />
                </Flex>
              </form>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </FormProvider>
    );
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px" overflowY="auto">
        <ModalHeader
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          {idEdit ? "Edit" : "Create Admin"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px" minW="500px">
          {idEdit ? renderFormEdit() : renderForm()}
        </ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto" gap="16px">
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="machine-form"
            onClick={() => {
              onClose();
            }}
          >
            CANCEL
          </ButtonType>
          <ButtonType
            mt={4}
            btnType="primary"
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            fontSize="16px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
