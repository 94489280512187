import { Icon } from "@chakra-ui/icons";
import {
  Flex,
  Td,
  Text,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import CheckBoxType from "components/Checkbox";
import { EditIcon } from "components/Icons/EditIcon";
import { useMemo } from "react";

export default function ContactTemplateManagementRow({
  item,
  index,
  checkedIds,
  setCheckedIds,
  handleOpenEditModal,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const isChecked = useMemo(() => {
    return checkedIds.some((itemCheck) => itemCheck?.id === item?.id);
  }, [item?.id, checkedIds]);


  const handleCheckboxChange = () => {
    if (isChecked) {
      setCheckedIds(
        checkedIds.filter((itemCheck) => itemCheck?.id !== item?.id)
      );
    } else {
      setCheckedIds([...checkedIds, item]);
    }
  };

  return (
    <>
      <Tr>
        <Td width="10px">
          <CheckBoxType
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            colorScheme="teal"
          />
        </Td>
        <Td w="50px">
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {index}
          </Text>
        </Td>
        <Td>
          <Text color={textColor} fontSize="sm" fontWeight={400}>
            {item?.name}
          </Text>
        </Td>
        <Td>
          <Flex gap="10px">
            <Icon
              onClick={() => {
                handleOpenEditModal(item);
              }}
              fontSize="30px"
              borderRadius="8px"
              p="7px"
              cursor="pointer"
              bg="#1480FF"
              as={EditIcon}
            />
          </Flex>
        </Td>
      </Tr>
    </>
  );
}
