import { Center, Flex, Icon, useDisclosure, useToast } from "@chakra-ui/react";
import { getHunterNFTs } from "api/hunterNFTs.api";
import ButtonType from "components/Button";
import { NoDataIcon } from "components/Icons/NoDataIcon";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ModalConfirm from "components/Modal/ModalConfirm";
import useWithToast from "hooks/useWithToast";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import ListHunter from "./listHunter";
import ModalSellNFTHunter from "./modalSell";
import { cancelSellProductNFTs } from "api/products.api";

export default forwardRef(function TabPriceHunterNFT(
  { type = "READY_FOR_SALE", isActive },
  ref
) {
  const LIMIT = 6;
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const {
    isOpen: isOpenSell,
    onOpen: onOpenSell,
    onClose: onCloseSell,
  } = useDisclosure();

  const {
    isOpen: isOpenCancelSell,
    onOpen: onOpenCancelSell,
    onClose: onCloseCancelSell,
  } = useDisclosure();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const [itemSell, setItemSell] = useState(null);

  const [params, setParams] = useState({
    limit: LIMIT,
    page: 1,
    "sale-status": type,
  });

  const [hunterNFTs, setHunterNFTs] = useState([]);

  const [totalPage, setTotalPage] = useState(1);

  const handleOpenSell = (item) => {
    setItemSell(item);
    onOpenSell();
  };

  const handleOpenCancelSell = (item) => {
    setItemSell(item);
    onOpenCancelSell();
  };

  const handleSellSuccess = useCallback(
    (list = []) => {
      const newHunterNFTs = hunterNFTs?.filter((item) => {
        if (!list?.includes(item?.product_model?.id)) {
          return item;
        }
      });
      setHunterNFTs(newHunterNFTs);
    },
    [hunterNFTs]
  );

  const handleCancelSell = async () => {
    try {
      const res = await cancelSellProductNFTs(itemSell?.product_model?.id);
      if (res) {
        handleSellSuccess([itemSell?.product_model?.id], "READY_FOR_SALE");
        showToastSuccess({
          title: "Cancel sell successfully!",
        });
        onCloseCancelSell();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const getListHunterNFTs = useCallback(async () => {
    try {
      setIsLoadingMore(true);
      const { data } = await getHunterNFTs(params);
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        setHunterNFTs((prev) => {
          return prev?.concat(list);
        });
      }
      setTotalPage(data?.data?.total_page);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoadingMore(false);
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    if (isActive) {
      getListHunterNFTs();
    }
  }, [getListHunterNFTs, isActive]);

  useImperativeHandle(ref, () => ({
    resetAllData() {
      setHunterNFTs([]);
      setParams({
        limit: LIMIT,
        page: 1,
        "sale-status": type,
      });
      setIsLoading(true);
      setTotalPage(1);
    },
    handleSellBatchSuccess(list = []) {
      if (type === "SALE") {
        setHunterNFTs(hunterNFTs?.concat(list));
      } else {
        if (hunterNFTs && hunterNFTs?.length > 0 && list && list?.length > 0) {
          const newHunterNFTs = hunterNFTs?.filter((item) => {
            if (
              !list
                ?.map((item) => item?.product_model?.id)
                ?.includes(item?.product_model?.id)
            ) {
              return item;
            }
          });
          setHunterNFTs(newHunterNFTs);
        }
      }
    },
  }));

  const renderForm = () => {
    return (
      <>
        <Flex direction="column" alignItems="center" gap="24px">
          {hunterNFTs?.length > 0 ? (
            <>
              <ListHunter
                data={hunterNFTs}
                isSell={type === "SALE"}
                onOpenSell={handleOpenSell}
                onOpenCancelSell={handleOpenCancelSell}
              />
              {totalPage > params?.page && (
                <ButtonType
                  fontSize="16px"
                  fontWeight="500"
                  h="44px"
                  btnType="primary-new-outline"
                  borderRadius="30px"
                  boxShadow="none"
                  color="#000000"
                  p="12px 40px"
                  isLoading={isLoadingMore}
                  onClick={() => {
                    setParams((prev) => ({
                      ...prev,
                      limit: LIMIT,
                      page: prev?.page + 1,
                    }));
                  }}
                >
                  Load More
                </ButtonType>
              )}
            </>
          ) : (
            <Center w="100%">
              <Icon as={NoDataIcon} w="250px" h="200px" />
            </Center>
          )}
        </Flex>
        <ModalConfirm
          isOpen={isOpenCancelSell}
          onClose={onCloseCancelSell}
          onSubmit={handleCancelSell}
          title="Cancel Sell"
          description={"Are you sure you want to cancel sell this NFT?"}
        />
        {isOpenSell && (
          <ModalSellNFTHunter
            isOpen={isOpenSell}
            itemSell={itemSell}
            onClose={onCloseSell}
            handleSellSuccess={handleSellSuccess}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Center minH="200px" m="auto">
          <LoadingSpinner />
        </Center>
      ) : (
        renderForm()
      )}
    </>
  );
});
