export const SORT = [
  { label: "Desc", value: "DESC" },
  { label: "Asc", value: "ASC" },
];

export const STATUS_CONTACT = [
  { label: "Open", value: "OPEN" },
  { label: "Resolve", value: "RESOLVE" },
  { label: "Closed", value: "CLOSED" },
];
