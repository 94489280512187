import {
  Box,
  Center,
  Flex,
  Grid,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import ModalConfirm from "components/Modal/ModalConfirm";
import useWithToast from "hooks/useWithToast";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import ModalSellNFTBountyBall from "../modalSell";
import InformationNFT from "./informationNFT";
import { getRateApi } from "api/rate.api";
import { cancelSellProductNFTs } from "api/products.api";
import { getDetailBountyBallNFT } from "api/bountyBall.api";

export default function DetailNFTsBountyBall() {
  const textColor = useColorModeValue("#000000", "white");
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const [dataDetail, setDataDetail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [rateBCoinToBTH, setRateBCoinToBTH] = useState(1);
  const [rateBCoinToUSD, setRateBCoinToUSD] = useState(1);

  const {
    isOpen: isOpenSell,
    onOpen: onOpenSell,
    onClose: onCloseSell,
  } = useDisclosure();

  const {
    isOpen: isOpenCancelSell,
    onOpen: onOpenCancelSell,
    onClose: onCloseCancelSell,
  } = useDisclosure();

  const { id } = useParams();

  const getDetail = async (id) => {
    try {
      setIsLoading(true);
      const { data } = await getDetailBountyBallNFT(id);
      setDataDetail(data?.data);
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getRate = async () => {
    try {
      const { data } = await getRateApi();
      const list = data?.data?.records;
      if (list && list?.length > 0) {
        list?.map((item) => {
          if (item?.id === "BCOIN_TO_BTH") {
            setRateBCoinToBTH(Number(item?.exchange_rate));
          } else if (item?.id === "BCOIN_TO_USD") {
            setRateBCoinToUSD(Number(item?.exchange_rate));
          }
        });
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  useEffect(() => {
    getDetail(id);
    getRate();
  }, [id]);

  const renderForm = () => {
    return (
      <Grid
        w="100%"
        maxW={{
          base: "637px",
          md: "1000px",
          xl: "1200px",
        }}
        m="auto"
        gap="60px"
        templateColumns={{
          base: "repeat(1, minmax(0, 1fr))",
          md: "repeat(2, minmax(0, 1fr))",
        }}
      >
        <Box>
          <Image
            src={dataDetail?.image_url}
            w={{
              base: "299px",
              xl: "430px",
              "2xl": "511px",
            }}
            h={{
              base: "299px",
              xl: "430px",
              "2xl": "511px",
            }}
            borderRadius="8px"
            alt="image-item"
          />
        </Box>
        <InformationNFT item={dataDetail} rateBCoinToBTH={rateBCoinToBTH} />
      </Grid>
    );
  };

  const handleSellDetailSuccess = (price) => {
    setDataDetail((prev) => {
      const productModel = { ...prev?.product_model };
      return {
        ...prev,
        product_model: {
          ...productModel,
          status: productModel?.status === "NEW" ? "SALE" : "NEW",
          price: price,
        },
      };
    });
  };

  const isSell = useMemo(() => {
    return dataDetail?.product_model?.status === "SALE";
  }, [dataDetail?.product_model?.status]);

  const handleCancelSell = async () => {
    try {
      const res = await cancelSellProductNFTs(dataDetail?.product_model?.id);
      if (res) {
        handleSellDetailSuccess(0);
        showToastSuccess({
          title: "Cancel sell successfully!",
        });
        onCloseCancelSell();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            gap={4}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              Detail NFTs
            </Text>
            <ButtonType
              fontSize="16px"
              fontWeight="500"
              h="44px"
              w="120px"
              btnType="primary-new"
              onClick={() => {
                if (isSell) {
                  onOpenCancelSell();
                } else {
                  onOpenSell();
                }
              }}
            >
              {isSell ? "Cancel Sell" : "Sell"}
            </ButtonType>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" pt="24px">
          {isLoading ? (
            <Center minH="200px" m="auto">
              <LoadingSpinner />
            </Center>
          ) : (
            renderForm()
          )}
        </CardBody>
      </Card>
      <ModalConfirm
        isOpen={isOpenCancelSell}
        onClose={onCloseCancelSell}
        onSubmit={handleCancelSell}
        title="Cancel Sell"
        description={"Are you sure you want to cancel sell this NFT?"}
      />
      {isOpenSell && (
        <ModalSellNFTBountyBall
          isOpen={isOpenSell}
          itemSell={dataDetail}
          onClose={onCloseSell}
          handleSellDetailSuccess={handleSellDetailSuccess}
        />
      )}
    </Flex>
  );
}
