import { Flex, Grid } from '@chakra-ui/react'
import FormTextAria from 'components/form/FormTextAria'
import React from 'react'

const TRANS_LABEL = {
  en: {
    message: "Message",
    enterMessage: "Enter message"
  },
  jp: {
    message: "メッセージ",
    enterMessage: "メッセージを入力してください"
  },
};

export default function tabPanel(props) {
  const { trans } = props

  const transLabel = (label) => {
    return TRANS_LABEL[trans][label];
  };

  return (
    <Flex direction="column" w="100%" gap={4}>
      <Grid 
        rowGap={4}
        columnGap={16}
      >
        <Flex direction="column" gap={4}>
          {trans === "en" ? (
            <>
              <FormTextAria
                label={transLabel("message")}
                name="message"
                h="200px"
                isRequired
              />
            </>
          ) : (
            <>
              <FormTextAria
                label={transLabel("message")}
                name="message_jp"
                h="200px"
                isRequired
              />
            </>
          )}
        </Flex>
      </Grid>
    </Flex>
  )
}
