import { Flex, useDisclosure, useToast } from "@chakra-ui/react";
import { deleteMaintenance } from "api/maintenance.api";
import ButtonType from "components/Button";
import DeleteModal from "components/Modal/deleteModal";
import useWithToast from "hooks/useWithToast";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

export default function ActionContact({
  handleOpenCreate,
  checkedIds,
  setCheckedIds,
  setParams,
  params,
}) {
  const { t } = useTranslation("mantenance");
  const toast = useToast();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { showToastSuccess } = useWithToast();

  const handleDelete = async () => {
    try {
      if (checkedIds?.length) {
        const res = await deleteMaintenance(checkedIds[0].id);
        if (res) {
          showToastSuccess({
            title: "Delete Success",
          });
          onClose();
          setCheckedIds([])
          setParams({
            ...params,
            page: 1,
          });
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Flex gap={4} flexWrap="wrap" alignItems="flex-end">
      <Flex alignItems="flex-end" gap={6} mb="10px">
        <ButtonType
          w="120px"
          text="ADD"
          fontSize="16px"
          fontWeight="700"
          onClick={() => {
            handleOpenCreate();
          }}
        />
        <ButtonType
          text="DELETE"
          w="120px"
          fontSize="16px"
          fontWeight="700"
          btnType="primary-new-outline"
          disabled={checkedIds?.length !== 1}
          onClick={onOpen}
        />
      </Flex>
      <DeleteModal
        isOpen={isOpen}
        onClose={onClose}
        handleDelete={handleDelete}
        title={t("delete_maintenance_setting_title")}
        content={t("content_delete")}
        btnYes={t("btn_yes")}
        btnNo={t("btn_no")}
      />
    </Flex>
  );
}
