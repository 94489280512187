import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import TabPriceHunterNFT from "./tabPrice";

export default function TabChain(props) {
  const textColor = useColorModeValue("#000000", "white");
  const [tabIndexPrice, setTabIndexPrice] = useState(0);

  const refTabSell = useRef();
  const refTabNotSell = useRef();

  const renderForm = () => {
    return (
      <Flex w="100%" direction="column" alignItems="center" gap="24px" pb="4px">
        <Tabs
          onChange={(index) => {
            if (index === 0) {
              refTabNotSell.current.resetAllData();
            } else {
              refTabSell.current.resetAllData();
            }
            setTabIndexPrice(index);
          }}
          variant="unstyled"
          w="100%"
        >
          <TabList gap={2} w="fit-content" bg="#F8F9FA" borderRadius="8px">
            <Tab
              fontSize="14px"
              fontWeight="400"
              color={textColor}
              _selected={{
                p: "2px 16px 2px 16px",
                m: "4px",
                boxShadow:
                  "0px 2px 4px -1px #00000012,  0px 4px 6px -1px #0000001F",
                bg: "#FFFFFF",
                borderRadius: "4px",
              }}
            >
              Priced item list
            </Tab>
            <Tab
              fontSize="14px"
              fontWeight="400"
              color={textColor}
              _selected={{
                p: "2px 16px 2px 16px",
                m: "4px",
                boxShadow:
                  "0px 2px 4px -1px #00000012,  0px 4px 6px -1px #0000001F",
                bg: "#FFFFFF",
                borderRadius: "4px",
              }}
            >
              Unpriced item list
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel padding="24px 0">
              <TabPriceHunterNFT
                isActivePrice={tabIndexPrice === 0}
                type="SALE"
                ref={refTabSell}
                {...props}
              />
            </TabPanel>
            <TabPanel padding="24px 0">
              <TabPriceHunterNFT
                isActivePrice={tabIndexPrice === 1}
                type="READY_FOR_SALE"
                ref={refTabNotSell}
                {...props}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column">
      {renderForm()}
    </Flex>
  );
}
