import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import ButtonType from "components/Button";
import FormInput from "components/form/FormInput";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { createContractApi } from "api/contract.api";
import useWithToast from "hooks/useWithToast";
import FormSelect from "components/form/FormSelect";

export default function ModalAddContract({
  isOpen,
  onClose,
  tokenType,
  chainList
}) {
  const textColor = useColorModeValue("#000000", "white");
  const { showToastSuccess, showToastFail } = useWithToast();

  const defaultValues = {
    chain_name: chainList[0].value,
    address: "",
  };

  const schemaAddContract = () =>
    yup.object().shape({
      chain_name: yup
        .string()
        .trim()
        .required("Required"),
      address: yup
        .string()
        .trim()
        .required("Required"),
    });

  const form = useForm({
    resolver: yupResolver(schemaAddContract()),
    defaultValues,
  });
  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
    setValue,
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const res = await createContractApi({
      "chain_enum": data.chain_name,
      "contract_address": data.address,
      "token_type": tokenType
    });
    if (res?.data?.success) {
      showToastSuccess({
        title: "Contract address created successfully.",
      });
      onClose()
    } else {
      showToastFail({
        title: `${res?.data?.messages[0]}`,
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            {/* <FormInput type="text" label="Chain Name" name="chain_name" placeholder="Select Chain Name"/> */}
            <FormSelect
              name="chain_name"
              label="Chain Name"
              defaultValue={chainList[0]}
              options={chainList}
              onChange={(e) => {
                setValue("chain_name", e.target.value);
              }}
            />
            <FormInput type="text" label="Contract Address" name="address" placeholder="Contract Address"/>
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="685px">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          Add Contract
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          display="flex"
          alignItems="center"
          gap="16px"
        >
          <ButtonType
            mt={4}
            btnType="primary-new-outline"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            onClick={onClose}
          >
            Cancel
          </ButtonType>
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="100%"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
          >
            Add
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
