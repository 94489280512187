import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import useWithToast from "hooks/useWithToast.js";
import {
  sellMultipleProductNFTs,
  sellSingleProductNFTs,
} from "api/products.api.js";

export default function ModalSellNFTHunter({
  isOpen,
  onClose,
  itemSell,
  type = "single",
  getListHunterNFTs,
  setHunterNFTs,
  setNFTsChecked,
}) {
  const toast = useToast();
  const { showToastSuccess } = useWithToast();

  const handleClose = () => {
    onClose();
  };

  const handleSubmitSell = async () => {
    try {
      if (type === "single") {
        const res = await sellSingleProductNFTs({
          product_id: itemSell?.product_model?.id,
          price: itemSell?.product_model?.price,
        });
        if (res) {
          showToastSuccess({
            title: "Sell successfully!",
          });
          handleClose();
        }
      } else {
        const NFTsSell = itemSell?.map((item) => {
          return {
            product_id: item?.product_model?.id,
            price: itemSell?.product_model?.price,
          };
        });
        const res = await sellMultipleProductNFTs(NFTsSell);
        if (res) {
          showToastSuccess({
            title: "Sell successfully!",
          });
          setHunterNFTs([])
          setNFTsChecked([])
          getListHunterNFTs();
          handleClose();
        }
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      onClose={() => {
        handleClose();
      }}
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent maxW="685px" p="24px">
        <ModalHeader w="100%" textAlign="center" margin="auto">
          <Text fontSize="24px" fontWeight="700">
            Sell batch NFTs
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="0px">
          <Text 
            fontSize="24px"
            textAlign="center"
          >Are you sure to sell?</Text>
        </ModalBody>
        <ModalFooter w="100%" textAlign="center" margin="auto" p="16px 0px">
          <Flex
            w="100%"
            alignItems="center"
            justifyContent="space-between"
            gap="16px"
            pb="4px"
          >
            <ButtonType
              mt={4}
              m="auto"
              w="100%"
              h="46px"
              borderRadius="8px"
              btnType="primary-new-outline"
              fontSize="16px"
              fontWeight="500"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </ButtonType>
            <ButtonType
              mt={4}
              m="auto"
              w="100%"
              h="46px"
              borderRadius="8px"
              type="submit"
              form="list-sell-nft-hunter-form-id"
              fontSize="16px"
              fontWeight="500"
              disabled={itemSell?.length <= 0}
              onClick={handleSubmitSell}
            >
              Sell
            </ButtonType>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
