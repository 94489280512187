import {
  Grid,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  updateMachineSetting,
} from "api/machine.api";
import ButtonType from "components/Button";
import FormSelect from "components/form/FormSelect";
import { FormProvider, useForm } from "react-hook-form";
import { STATUS_CONTACT } from "../constants";
import FormTextAria from "components/form/FormTextAria";

export default function ContactUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const toast = useToast();
  const textColor = useColorModeValue("#000000", "white");
  const form = useForm({
    // resolver: yupResolver(schemaMachine()),
    // defaultValues: {
    //   machine_type: dataDetail ? dataDetail?.machine_type : MACHINE_TYPE_OPTIONS[0].value
    // }
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = form;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    const dataSubmit = {
      crane_machine_id: data?.machine_id,
      status: data?.status,
      machine_type: data?.machine_type,
      game_mode_id: data?.game_mode_id,
      position_id: "1",
      icon_url: data?.icon_url,
    };
    try {
      const res = await updateMachineSetting(dataDetail?.id, dataSubmit);
      if (res?.data?.success) {
        toast({
          title: "Machine updated.",
          description: "Machine updated.",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
        handleCreateOrUpdateSuccess();
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };


  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid templateColumns="repeat(1, 1fr)" gap={4}>
            <FormSelect
              name="status"
              label="Status Contact"
              defaultValue={STATUS_CONTACT[0].value}
              isRequired
              options={STATUS_CONTACT}
            />
            <FormTextAria
              label="Noted"
              name="note"
              maxLength={500}
              rows={7}
              h="150px"
            />
          </Grid>
        </form>
      </FormProvider>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW="1000px" overflowY="auto">
        <ModalHeader
          borderBottom="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
          color={textColor}
        >
          UPDATE CONTACT
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p="24px">{renderForm()}</ModalBody>
        <ModalFooter
          borderTop="1px solid #EEEFF2"
          w="100%"
          textAlign="center"
          margin="auto"
        >
          <ButtonType
            mt={4}
            type="submit"
            m="auto"
            w="120px"
            h="46px"
            borderRadius="5px"
            form="machine-form"
            isLoading={isSubmitting}
            // onClick={() => {
            //   handleSubmit(onSubmit);
            // }}
          >
            SAVE
          </ButtonType>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
