import * as yup from "yup";

const schemaCreateContactTemplate = () =>
  yup.object().shape({
    message: yup
      .string()
      .trim()
      .required("Required")
      .max(300, "Message must be at most 300 characters"),
    message_jp: yup
      .string()
      .trim()
      .required("Required")
      .max(300, "Message must be at most 300 characters"),
  });

export default schemaCreateContactTemplate;
