export const LIMIT_PAGE = 10;
export const FIRST_PAGE = 1;
export const MAX_FILE_SIZE = 5;
export const LIMIT_FULL = 999999;

export const STATUS = {
  ACTIVE: "ACTIVE",
  NO_ACTIVE: "NO_ACTIVE",
  BLOCKED: "BLOCKED",
  AVAILABLE: "Available",
  MAINTENANCE: "Maintenance",
  EVENT: "EVENT",
  PRIZE: "PRIZE",
  FREE: "FREE",
  FAIL: "FAIL",
  MISSING: "MISSING",
  CLOSE: "CLOSE",
  OK: "OK",
  DISCONNECT: "DISCONNECT",
  LEFT: "LEFT",
  RIGHT: "RIGHT",
  FRONT: "FRONT",
  BACK: "BACK",
  SIDE: "SIDE",
  TWO_CLAWS: "TWO_CLAWS",
  THREE_CLAWS: "THREE_CLAWS",
};

export const STATUS_RATE = {
  BCOIN_TO_BTH: "BCOIN_TO_BTH",
  BCOIN_TO_SPHERE: "BCOIN_TO_SPHERE",
  BCOIN_TO_USD: "BCOIN_TO_USD",
  BCOIN_TO_YEN: "BCOIN_TO_YEN",
};

export const TOKEN_TYPE = {
  HUNTER_NFT: "HUNTER_NFT",
  GAUNTLET_NFT: "GAUNTLET_NFT",
  GENESIS_NFT: "GENESIS_NFT",
  BOUNTY_BALL_NFT: "BOUNTY_BALL_NFT",
  COLLAB_NFT: "COLLAB_NFT",
  LOCK_BNB_NFT: "LOCK_BNB_NFT",
  LOCK_IMMUTABLE_PASSPORT_NFT: "LOCK_IMMUTABLE_PASSPORT_NFT",
  LOCK_DMM_NFT: "LOCK_DMM_NFT",
};

export const MACHINE_TYPE_OPTIONS = [
  { label: "2 Claws", value: STATUS.TWO_CLAWS },
  { label: "3 Claws", value: STATUS.THREE_CLAWS },
];

export const DEDICATED_TO = {
  true: "Free play",
  false: "Get Prize",
};

export const DEDICATED_TO_OPTIONS = [
  { label: "Free play", value: STATUS.FREE },
  { label: "Get Prize", value: STATUS.PRIZE },
  { label: "Event", value: STATUS.EVENT },
];

export const STATE_OPTIONS = [
  { label: "Maintenance", value: "NO_ACTIVE" },
  { label: "Available", value: "ACTIVE" },
];

export const CABINET_TYPE_OPTIONS = [
  { label: "Cabinet 1", value: "1772835644541865986" },
];

export const GENRE_TAGS_OPTIONS = [
  { label: "Genre Tags 1", value: "Genre_Tags_1" },
];

export const PAYEE_OPTIONS = [
  { label: "RSharing 1", value: "1772835644713832449" },
];

export const SALE_OPTIONS = [
  { label: "None", value: "none" },
  { label: "Yes", value: "yes" },
];

export const BOUNTY_BALL_OPTIONS = [
  { label: "Bounty Ball NFT", value: "bounty_ball_nft" },
  { label: "Others", value: "other" },
];

export const PRIZE_TYPE_OPTIONS = [
  // { label: "Digital Prize", value: "digital_prize" },
  { label: "TREASURE_BOX", value: "1765994158289481733" },
];

export const ACTION_PAGE = {
  VIEW: "VIEW",
  CREATE: "CREATE",
  EDIT: "EDIT",
};

export const UMIT_OPTIONS = [
  { label: "C Coin 1", value: "C_COIN_1" },
  { label: "C Coin 2", value: "C_COIN_2" },
  { label: "B Coin", value: "B_COIN" },
  { label: "BTH TOKEN", value: "BTH_TOKEN" },
  { label: "TICKET NFT", value: "TICKET_NFT" },
  { label: "ORB", value: "ORB" },
];

export const TYPE_PRIZE = {
  TREASURE_BOX: "Treasure Box",
  DIGITAL_PRIZE: "Digital Prize",
  IN_HOUSE_NFT: "In-house NFT",
  COLLAB_NFT: "Collab NFT",
  LISTING_NFT: "Listing NFT",
};

export const GAME_MODE_OPTIONS = [
  { label: "Training Mode", value: "TRAINING" },
  { label: "Free play", value: "FREE_PLAY" },
  { label: "Basic Play", value: "BASIC" },
  { label: "Battle Arena", value: "PVP" },
  { label: "League Tournament", value: "TOURNAMENT" },
  { label: "League Battle Royale", value: "BATTLE_ROYALE" },
  // { label: "Group vs Group", value: "Group vs Group" },
];

export const ROLE_OPTIONS = {
  SUPPER_ADMIN: "Super Admin",
  ADMIN: "Admin",
  MARKET_PLACE_ADMIN: "MKP Admin",
  USER: "User",
};

export const OPTIONS_SKILL_RANK = [
  {
    label: "Default",
    value: "DEFAULT",
  },
  {
    label: "Iron",
    value: "IRON",
  },
  {
    label: "Bronze",
    value: "BRONZE",
  },
  {
    label: "Silver",
    value: "SILVER",
  },
  {
    label: "Gold",
    value: "GOLD",
  },
  {
    label: "Platium",
    value: "PLATINUM",
  },
  {
    label: "Emerald",
    value: "EMERALD",
  },
  {
    label: "Diamond",
    value: "DIAMOND",
  },
  {
    label: "Master",
    value: "MASTER",
  },
  {
    label: "Grandmaster",
    value: "GRANDMASTER",
  },
  {
    label: "Challenger",
    value: "CHALLENGER",
  },
];

export const NFT_CHAIN = [
  { label: "Immutable zkEVM", value: "IMX", chain_id: "13371" },
  { label: "Immutable zkEVM Testnet", value: "T_IMX", chain_id: "13473" },
  { label: "BNB Smart Chain", value: "BNB", chain_id: "56" },
  { label: "BNB Smart Chain Testnet", value: "T_BNB", chain_id: "97" },
];

export const GENESIS_NFT_CHAIN = [
  { label: "Immutable zkEVM", value: "IMX", chain_id: "13371" },
  { label: "Immutable zkEVM Testnet", value: "T_IMX", chain_id: "13473" },
  { label: "DM2 Verse", value: "DM2_VERSE", chain_id: "68770" },
  { label: "DM2 Verse Testnet", value: "T_DM2_VERSE", chain_id: "68775" },
  { label: "Polygon", value: "POLYGON", chain_id: "137" },
  { label: "Polygon Testnet", value: "T_POLYGON", chain_id: "80002" },
];

export const CODE_MAINTENANCE = [
  { label: "UPDATE", value: "UPDATE" },
  { label: "HOT_FIX", value: "HOT_FIX" },
];

export const EVENT_WEBSOCKET = {
  NUMBER_USER_ONLINE: "NUMBER_USER_ONLINE",
};

export const RARITY = {
  RARITY_COMMON: "COMMON",
  RARITY_UNCOMMON: "UNCOMMON",
  RARITY_EPIC: "EPIC",
  RARITY_RARE: "RARE",
  RARITY_LEGENDARY: "LEGENDARY",
};
