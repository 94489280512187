import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import ButtonType from "components/Button";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useCallback, useRef, useState } from "react";
import ModalSellBatchNFTHunter from "./modalSellBatch";
import TabPriceHunterNFT from "./tabPrice";

export default function ListNftHunterPage() {
  const textColor = useColorModeValue("#000000", "white");
  const [tabIndex, setTabIndex] = useState(0);

  const {
    isOpen: isOpenSellBatch,
    onOpen: onOpenSellBatch,
    onClose: onCloseSellBatch,
  } = useDisclosure();

  const refTabSell = useRef();
  const refTabNotSell = useRef();

  const handleSellSuccess = useCallback(
    (list = [], status = "SALE") => {
      if (tabIndex === 0) {
        refTabSell.current.handleSellBatchSuccess(list, status);
      } else {
        refTabNotSell.current.handleSellBatchSuccess(list, status);
      }
      onCloseSellBatch();
    },
    [tabIndex]
  );

  const renderForm = () => {
    return (
      <Flex w="100%" direction="column" alignItems="center" gap="24px" pb="4px">
        <Tabs
          onChange={(index) => {
            if (index === 0) {
              refTabNotSell.current.resetAllData();
            } else {
              refTabSell.current.resetAllData();
            }
            setTabIndex(index);
          }}
          variant="unstyled"
          w="100%"
        >
          <TabList gap={2} w="fit-content" bg="#F8F9FA" borderRadius="8px">
            <Tab
              fontSize="14px"
              fontWeight="400"
              color={textColor}
              _selected={{
                p: "2px 16px 2px 16px",
                m: "4px",
                boxShadow:
                  "0px 2px 4px -1px #00000012,  0px 4px 6px -1px #0000001F",
                bg: "#FFFFFF",
                borderRadius: "4px",
              }}
            >
              List price
            </Tab>
            <Tab
              fontSize="14px"
              fontWeight="400"
              color={textColor}
              _selected={{
                p: "2px 16px 2px 16px",
                m: "4px",
                boxShadow:
                  "0px 2px 4px -1px #00000012,  0px 4px 6px -1px #0000001F",
                bg: "#FFFFFF",
                borderRadius: "4px",
              }}
            >
              List not price
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <TabPriceHunterNFT
                isActive={tabIndex === 0}
                type="SALE"
                ref={refTabSell}
              />
            </TabPanel>
            <TabPanel>
              <TabPriceHunterNFT
                isActive={tabIndex === 1}
                type="READY_FOR_SALE"
                ref={refTabNotSell}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Card px="24px">
        <CardHeader>
          <Flex
            w="100%"
            gap={4}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text color={textColor} fontSize="lg" fontWeight="bold">
              NFTs Collection
            </Text>
            <ButtonType
              fontSize="16px"
              fontWeight="500"
              h="44px"
              btnType="primary-new"
              onClick={() => {
                onOpenSellBatch();
              }}
            >
              Sell batch NFTs
            </ButtonType>
          </Flex>
        </CardHeader>
        <CardBody overflowX="auto" pt="24px">
          {renderForm()}
        </CardBody>
      </Card>

      <ModalSellBatchNFTHunter
        isOpen={isOpenSellBatch}
        onClose={onCloseSellBatch}
        handleSellSuccess={handleSellSuccess}
      />
    </Flex>
  );
}
