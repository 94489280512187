import { Box, Flex, Image, Tab, TabList, Tabs, Text } from "@chakra-ui/react";
import IconRemoteClaw from "../../../../assets/img/icon-remote-claw.png";
import { useMemo } from "react";
import BigNumber from "bignumber.js";
import { FormattedNumber } from "react-intl";
import styled from "styled-components";
import TextShadow from "components/common/text-shadow";
import { BoldTextWhiteWithShadow } from "components/common/text";
import { ShowPercent } from "components/ItemNftNew/ItemPercent";
import ChartInfoNft from "./ChartInfoNft";

const StyledIconRarity = styled.div`
  width: auto;
  border-radius: 4px;
  padding: 5px 12px;
  margin: auto;

  background-color: ${props => props.bgRarity};
  border: 2px solid #000000;
  box-shadow: 1px 3px 0px 2px #000000;
  transform: skew(-12deg);

  .rarity-content {
    display: flex;
    align-items: center;
    gap: 4px;

    transform: skew(14deg);
    .icon-rarity {
      width: 22.36px;
      height: 22.36px;
    }
  }
`;

export default function InformationNFT({ item, IconCard }) {

  return (
    <Flex direction="column" alignItems="center" gap="20px">
      <Flex direction="column" alignItems="center" gap="4px">
        <StyledIconRarity bgRarity={IconCard.bgRarity}>
          <div className="rarity-content">
            <TextShadow
              fontSize="12px"
              fontWeight="900"
              colorBox="#000000"
              colorText="#ffffff"
              data-text={item.rarity}
              className="text"
            >
              {item.rarity}
            </TextShadow>
          </div>
        </StyledIconRarity>
        <BoldTextWhiteWithShadow size="20">
          {item?.nft_information?.name}
        </BoldTextWhiteWithShadow>
        <Flex direction="column" alignItems="center">
          <Image
            src={item?.image_url}
            w={{
              base: "240px",
              xl: "300px",
              "2xl": "511px",
            }}
            h={{
              base: "240px",
              xl: "300px",
              "2xl": "511px",
            }}
            borderRadius="8px"
            alt="image-item"
          />
          <ShowPercent 
            currentGauge={item?.current_life_gauge} 
            maxGauge={item?.max_life_gauge}
          />
        </Flex>
      </Flex>
      <Flex>
        <ChartInfoNft />
      </Flex>
    </Flex>
  );
}
