import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  Tabs,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import schemaCreateContactTemplate from "./schema";
import ButtonType from "components/Button";
import TemplateTabPanel from "./tabPanel";

export default function ContactTemplateCreateAndUpdate({
  isOpen,
  onClose,
  dataDetail,
  handleCreateOrUpdateSuccess,
}) {
  const textColor = useColorModeValue("#000000", "white");
  const [tabIndex, setTabIndex] = useState(0);
  const [trans, setTrans] = useState("en");
  const toast = useToast();
  const form = useForm({
    resolver: yupResolver(schemaCreateContactTemplate()),
  });

  const handleTabsChange = (index) => {
    setTabIndex(index);
    if (index === 1) {
      setTrans("jp");
    } else {
      setTrans("en");
    }
  };

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = form;

  const idEdit = useMemo(() => {
    return dataDetail?.id;
  }, [dataDetail]);

  useEffect(() => {
    if (dataDetail) {
      reset();
    } else {
      reset();
    }
  }, [dataDetail]);

  const onSubmit = async (data) => {
    console.log("🚀 ~ onSubmit ~ data:", data)
    try {
      if (idEdit) {
        console.log(idEdit);
      } else {
        console.log(11);
      }
    } catch (error) {
      toast({
        description: error?.message || error?.messages[0],
        status: "error",
        position: "bottom-right",
      });
    }
  };

  const renderForm = () => {
    return (
      <FormProvider {...form}>
        <form id="machine-form" onSubmit={handleSubmit(onSubmit)}>
        <Tabs
            mb={4}
            index={tabIndex}
            variant="unstyled"
            onChange={handleTabsChange}
          >
            <TabList gap={2}>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                English
              </Tab>
              <Tab
                fontSize="16px"
                fontWeight="700"
                border="1px solid #EEEFF2"
                boxShadow="none !important"
                borderRadius="5px"
                color={textColor}
                _selected={{
                  color: "white",
                  backgroundImage: "linear-gradient(310deg,#2152ff,#21d4fd)",
                }}
              >
                Japanese
              </Tab>
            </TabList>
          </Tabs>
          <TemplateTabPanel 
            trans={trans}
          />
        </form>
      </FormProvider>
    );
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent maxW="787px" overflowY="auto">
          <ModalHeader
            borderBottom="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
            color={textColor}
          >
            {idEdit ? "EDIT CONTACT TEMPLATE" : "NEW CONTACT TEMPLATE"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody
            p={{
              base: "24px 40px",
              md: "24px 80px",
            }}
            minW="620px"
          >
            {renderForm()}
          </ModalBody>
          <ModalFooter
            borderTop="1px solid #EEEFF2"
            w="100%"
            textAlign="center"
            margin="auto"
          >
            <ButtonType
              mt={4}
              btnType="primary"
              type="submit"
              m="auto"
              w="120px"
              h="46px"
              fontSize="16px"
              borderRadius="5px"
              form="machine-form"
              isLoading={isSubmitting}
            >
              SAVE
            </ButtonType>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
